import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons'

export default function EditingPanel(props) {

  return (
    <div style={{ display: (props.editPanelState ? 'block' : 'none') }}>
      <div className="map-place-centered"></div>
      <div className="editing-panel py-3">
        <Row>
          <Col>
            <font style={{fontSize: 'small'}}><FontAwesomeIcon icon={faLocationCrosshairs}/> {props.lat}, {props.lng}</font>
          </Col>
        </Row>
        <Row className="pt-3">
          <Col>
            <Button onClick={props.confirmPlacement} className="confirm-placement" size="lg">
                   Confirm placement
            </Button>
          </Col>
        </Row>
        <Row className="pt-3">
          <Col>
            <font style={{fontSize: 'small'}}>Edit mode is active. See <a href="/map/docs"><b>docs</b></a>.</font>
          </Col>
        </Row>
        <Row className="pt-2">
          <Col>
            <Button onClick={() => props.setEditPanelState(false)} className="exit-edit-mode" size="sm">
                   Exit edit mode
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  )
}
