import React from 'react'
import { Modal, Col, Row, Container, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'

export default function LoginRequired(props) {

  return (
    <Modal show={props.loginRequired} onHide={props.handleCloseLoginRequired} centered>
      <Modal.Header closeButton>
        <Modal.Title>Login required <FontAwesomeIcon icon={faLock}/></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <p>You must log in to submit reports, add new places, give personal ratings, and more.</p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Button style={{width: 'auto'}} href="/login">Go to login</Button>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  )
}
