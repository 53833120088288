import React from 'react'
import { Container, Row, Col, Badge, Button, CloseButton } from 'react-bootstrap'
import Drawer from 'react-drag-drawer'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBug, faCopy, faPlusSquare, faCheckSquare} from '@fortawesome/free-solid-svg-icons'
import PlaceAd from '../ads/placeAd'

export default function Sidebar(props) {

  // browser detect
  const isBrowser = typeof window !== 'undefined'

  // get thumb of pin image
  function resizedName(fileName = props.place.properties.picture.toString(), dimensions = '400x400') {
    const extIndex = fileName.lastIndexOf('.')
    const ext = fileName.substring(extIndex)
    return `${fileName.substring(0, extIndex)}_${dimensions}${ext}`
  }

  // show rate place modal, if user logged in
  function showRatePlace() {
    if (isBrowser) {
      if (!props.isLoggedIn) {
        props.setLoginRequired(true)
      } else {
        props.setRatePlace(true)
      }
    }
  }

  // show report place modal, if user logged in
  function showReportPlace() {
    if (isBrowser) {
      if (!props.isLoggedIn) {
        props.setLoginRequired(true)
      } else {
        props.setReport(true)
      }
    }
  }

  var sidebarContent

  if (props.place !== undefined) {
    sidebarContent = (
      <Container className="pb-3">
        <Row className="pt-3 pb-1" style={{ textAlign: 'center', backgroundColor: '#1f385a' }}>
          <Col xs={12} md={12}>
            <h5>{props.place.properties.title}</h5>
            <p>
              {props.place.address.city ? `${props.place.address.city}, ` : ''}
              {props.place.address.village ? `${props.place.address.village}, ` : ''}
              {props.place.address.county ? `${props.place.address.county}, ` : ''}
              {props.place.address.state ? `${props.place.address.state}, ` : ''}
              {props.place.address.country}
            </p>
          </Col>
          <Col xs={12} md={12} style={{ position: 'absolute', textAlign: 'right' }}>
            <CloseButton variant="white" onClick={() => props.setSidebarState(false)} />
          </Col>
        </Row>
        <Row className="pt-4 pb-4" style={{textAlign: 'left'}}>
          <Col xs={5} md={5}>
            <img
              src={`https://firebasestorage.googleapis.com/v0/b/pinavo-maps.appspot.com/o/pinavo%2Fmap%2Fpin%2F${props.place.properties.pid}%2Fthumbs%2F${resizedName()}?alt=media`}
              onError={(e)=>{e.target.onerror = null; e.target.src='https://firebasestorage.googleapis.com/v0/b/pinavo-maps.appspot.com/o/pinavo%2Fmap%2Fpin%2Fpin-placeholder.png?alt=media&token=6336c59f-f77d-4908-a703-3b9393d594bc'}}
              width="150"
              height="200"
              style={{ objectFit: 'contain' }}
              className="sidebar-img"
            />
          </Col>
          <Col xs={7} md={7}>
            {props.place.properties.description}
          </Col>
        </Row>
        <Row className="not-verified-notification">
          {(props.place.properties.verified === false ) ? <p>This place needs to be verified. If any information is wrong or there is a missing (or inappropriate) photo, please send us a &nbsp;<span className="pointer" onClick={() => showReportPlace()}><b><FontAwesomeIcon icon={faBug} style={{color: 'white'}}/> report</b></span>.</p> : ''}
        </Row>
        <Row className="py-2 justify-content-center" style={{ backgroundColor: '#1f385a' }}>
          <Col xs={12} md={12} style={{ fontSize: '15px' }}>
            {
              (() => {
                if (props.place.properties.type === '1')
                  return <Badge pill bg="secondary">standard</Badge>
                else if (props.place.properties.type === '2')
                  return <Badge pill bg="secondary">mineral</Badge>
                else if (props.place.properties.type === '3')
                  return <Badge pill bg="secondary">natural</Badge>
              })()
            }&nbsp;
            {
              (() => {
                if (props.place.properties.active === '0')
                  return <Badge pill bg="light" text="dark">inactive</Badge>
                else if (props.place.properties.active === '1')
                  return <Badge pill bg="primary">active</Badge>
                else if (props.place.properties.active === '2')
                  return <Badge pill bg="dark">unknown state</Badge>
              })()
            }&nbsp;
            {props.place.properties.verified ? <Badge pill bg="success">verified</Badge> : <Badge pill bg="danger">not verified</Badge>}
          </Col>
        </Row>
        <Row className="pt-2 justify-content-center text-center" style={{alignItems: 'center', backgroundColor: '#1f385a'}}>
          <Col xs={4} md={4}>
            {
              (() => {
                if (props.place.properties.activationType === '1')
                  return <span style={{fontSize:'17px'}}>Touch</span>
                else if (props.place.properties.activationType === '2')
                  return <span style={{fontSize:'17px'}}>Sensor</span>
                else if (props.place.properties.activationType === '3')
                  return <span style={{fontSize:'17px'}}>Stepping</span>
                else if (props.place.properties.activationType === '4')
                  return <span style={{fontSize:'17px'}}>Spring</span>
                else if (props.place.properties.activationType === '5')
                  return <span style={{fontSize:'17px'}}>Free-flow</span>
              })()
            }
          </Col>
          <Col xs={4} md={4}>
            {
              (() => {
                return (
                  <>
                    <img src="../assets/pin-icon/pin-rating-full.svg" style={{ width: '10px' }}/><span style={{fontSize: '17px'}}>&nbsp;{props.place.rating.avgRating.toFixed(1)}&nbsp;<span style={{color:'#ffffff91'}}>({props.place.rating.numRatings}x)</span></span>
                  </>
                )
              })()
            }
          </Col>
          <Col xs={4} md={4} onClick={() => showRatePlace()}>
            { props.userRating !== undefined ? <FontAwesomeIcon icon={faCheckSquare} style={{color: '#28a745', cursor: 'pointer'}} size={'2x'} /> : <FontAwesomeIcon icon={faPlusSquare} style={{color: 'orange', cursor: 'pointer'}} size={'2x'} /> }
            <br />
          </Col>
        </Row>
        <Row className="pb-2 justify-content-center text-center" style={{alignItems: 'center', backgroundColor: '#1f385a'}}>
          <Col xs={4} md={4}>
            <span style={{color:'#ffffff85'}}>activation type</span>
          </Col>
          <Col xs={4} md={4}>
            <span style={{color:'#ffffff85'}}>overall rating</span>
          </Col>
          <Col xs={4} md={4} onClick={() => showRatePlace()}>
            <span style={{color:'#ffffff85'}}>my rating</span>
          </Col>
        </Row>
        <Row className="map-links justify-content-center text-center py-3" style={{alignItems: 'center'}}>
          <Col xs={12} md={12} style={{alignItems: 'center'}}>
            <a href={`http://www.openstreetmap.org/?lat=${props.place.lat}&lon=${props.place.lng}&zoom=17&layers=M`} target="_blank" rel="noreferrer">
              <span className="pointer">
                &nbsp;OSM&nbsp;
              </span>
            </a>|
            <a href={`https://www.bing.com/maps?cp=${props.place.lat}~${props.place.lng}&lvl={zoomLevel}&sp=point.${props.place.lat}_${props.place.lng}_${props.place.properties.title}%20(Pinavo.cz)`} target="_blank" rel="noreferrer">
              <span className="pointer">
                &nbsp;Bing Maps&nbsp;
              </span>
            </a>|
            <a href={`https://mapy.cz/zakladni?x=${props.place.lng}&y=${props.place.lat}&z=11&source=coor&id=${props.place.lng}%2C${props.place.lat}`} target="_blank" rel="noreferrer">
              <span className="pointer">
                &nbsp;Mapy.cz&nbsp;
              </span>
            </a>|
            <a href={`https://www.google.com/maps/search/?api=1&query=${props.place.lat},${props.place.lng}`} target="_blank" rel="noreferrer">
              <span className="pointer">
                &nbsp;Google Maps&nbsp;
              </span>
            </a>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col style={{alignItems: 'center'}}>
            <CopyToClipboard text={`${props.place.lat} ${props.place.lng}`}>
              <span className="pointer" style={{color: '#ffffff85'}}>
                <FontAwesomeIcon icon={faCopy} style={{color: '#3771c8'}}/> coord
              </span>
            </CopyToClipboard>
            <span className="pointer" style={{color: '#ffffff85'}} onClick={() => showReportPlace()}>&nbsp;&nbsp;&nbsp;
              <FontAwesomeIcon icon={faBug} style={{color: 'orange'}}/> report
            </span>
          </Col>
        </Row>
        <Row className="py-2 justify-content-center">
          <Col style={{alignItems: 'center'}}>
            <font style={{color: '#ffffff85'}}>contributor: {props.place.contributor.name}</font><br />
            <font style={{color: '#ffffff85'}}>added: {props.place.added} | last modified: {props.place.lastModified}</font><br />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col style={{alignItems: 'center'}}>
            <font style={{color: '#ffffff82'}}>Place ID: {props.place.properties.pid}</font>
          </Col>
        </Row>
        <Row>
          <PlaceAd />
        </Row>
      </Container>
    )
  } else {
    sidebarContent = (
      <Container className="text-center pt-5">
        <p>No data available</p>
        <Button onClick={() => props.setSidebarState(false)}>Close</Button>
      </Container>
    )
  }

  const toggle = () => {
    props.setSidebarState(false)
  }

  return (
    <Drawer
      open={props.sidebarState}
      onRequestClose={toggle}
      allowClose={true}
      direction='left'
      modalElementClass='sidebar-modal'
      containerElementClass='sidebar-modal-container'
    >
      {sidebarContent}
    </Drawer>
  )
}
