const customImageInputStyle = theme => ({
  hidden: { display: 'none' },

  container: {
    margin: 'auto',
    cursor: 'pointer'
  },

  bigFile: {
    margin: 'left',
    width: 140,
    height: 140,
    borderStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fileThumb: {
    maxWidth: 140,
    maxHeight: 140
  },
  primaryBack: {
    background: '#11243f',
    textAlign: 'center'
  },
  whiteBack: {
    background: 'white'
  },

  errorBack: {
    background: '#dc3545',
    textAlign: 'center'
  }
})

export default customImageInputStyle
