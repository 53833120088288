import React, { Component  } from 'react'

class placeAd extends Component {

  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({})
  }

  render () {
    return(
      <div>
        <ins
          className="adsbygoogle"
          style={{display:'block'}}
          data-ad-client="ca-pub-2778261553644382"
          data-ad-slot="2871566534"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </div>)
  }
}

export default placeAd
