import React, { useState, useRef, useEffect } from 'react'
import { firestore } from 'gatsby-theme-firebase'
import Layout from '../components/layout/layout'
import { Map, TileLayer, Marker, LayersControl, ScaleControl, AttributionControl, ZoomControl } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import 'react-leaflet-markercluster/dist/styles.min.css'
import Control from 'react-leaflet-custom-control'
import Search from 'react-leaflet-search'
import LocateControl from '../components/map/locateControl'
import L from 'leaflet'
import { Alert, Spinner, Button, Tooltip, OverlayTrigger, Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faQuestion } from '@fortawesome/free-solid-svg-icons'
import ReportPlaceModal from'../components/modals/reportPlace'
import RatePlaceModal from '../components/modals/ratePlace'
import AddPlaceModal from'../components/modals/addPlace'
import LoginRequiredModal from'../components/modals/loginRequired'
import MapLegend from'../components/modals/mapLegend'
import MapSidebar from '../components/map/sidebar'
import EditingPanel from '../components/map/editingPanel'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { GetLoginStatus } from '../components/auth'
import { TbCurrentLocation, TbCirclePlus, TbBook2 } from 'react-icons/tb'

export default function MapBase() {

  // auth
  const [profile, setProfile] = useState()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  GetLoginStatus(setIsLoggedIn, setProfile)

  // browser detect
  const isBrowser = typeof window !== 'undefined'

  // firestore references
  const placesRef = firestore.collection('pin_places')
  const ratingsRef = firestore.collection('pin_ratings')

  // map props
  const southWest = [-90.0, -180.0]
  const northEast = [90.0, 180.0]
  const bounds = ([southWest, northEast])

  // map loading
  const [loading, setLoading] = useState(false)

  // places data
  const [places, setPlaces] = useState([])

  // data of selected place
  const [placeDocId, setPlaceDocId] = useState()
  const [placeId, setPlaceId] = useState()
  const [place, setPlace] = useState()

  // ratings data
  const [userRating, setUserRating] = useState()

  // handle sidebar refresh if user rates places
  const handleRefreshSidebar = () => setRefreshSidebar(true)
  const [refreshSidebar, setRefreshSidebar] = useState(false)

  // modal "rate place"
  const handleCloseRatePlace = () => setRatePlace(false)
  const [ratePlace, setRatePlace] = useState(false)

  // modal "report place"
  const handleCloseReport = () => setReport(false)
  const [report, setReport] = useState(false)

  // modal "add place"
  const handleCloseAddPlace = () => setAddPlace(false)
  const [addPlace, setAddPlace] = useState(false)
  const [geoAlert, setGeoAlert] = useState(false)
  const [restrictAlert, setRestrictAlert] = useState(false)

  // modals "map legend"
  const handleCloseLegend = () => setLegend(false)
  const handleShowLegend = () => setLegend(true)
  const [legend, setLegend] = useState(false)

  // edit mode
  const [editPanelState, setEditPanelState] = useState(false)
  const [editSuccess, setEditSuccess] = useState(false)
  const [editFailed, setEditFailed] = useState({show: false, error: ''})

  // edit mode - coordinates
  const [lat, setLat] = useState('')
  const [lng, setLng] = useState('')

  // modal "login required"
  const handleCloseLoginRequired = () => setLoginRequired(false)
  const [loginRequired, setLoginRequired] = useState(false)

  // place geo data
  const [address, setAddress] = useState('')

  // sidebar
  const [sidebarState, setSidebarState] = useState(false)

  // geo search control icon
  let searchIcon = null
  if (isBrowser) {
    searchIcon = L.icon({
      iconUrl: '../assets/pin-icon/SearchMarker.svg',
      iconRetinaUrl: '../assets/pin-icon/SearchMarker.svg',
      iconAnchor: [16, 50],
      popupAnchor: [0, -47],
      iconSize: [32, 50],
    })
  }

  // get url parameters
  var params = {}
  const map = useRef(null)

  if (isBrowser) {
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
      params[key] = value
    })
  }

  // handle click on marker
  function markerClick(place) {
    if (isBrowser) {
      window.history.pushState('', '', `?lat=${place.lat}&lng=${place.lng}&z=${map.current.leafletElement.getZoom()}`)
      setPlaceDocId(place.id)
      setPlaceId(place.pid)
      setSidebarState(true)
    }
  }

  function centerCoord() {
    const { lat, lng } = map.current.leafletElement.getCenter()
    setLat(lat)
    setLng(lng)
  }

  // enable edit mode, if user logged in
  function enableEditMode() {
    if (isBrowser) {
      if (!isLoggedIn) {
        setLoginRequired(true)
      } else {
        centerCoord()
        setEditPanelState(true)
      }
    }
  }

  // edit mode
  function confirmPlacement() {
    if (editPanelState === true) {
      const allowedCountry = ['cz', 'at', 'gb', 'be', 'bg', 'hr', 'cy', 'dk', 'ee', 'fi', 'fr', 'de', 'gr', 'hu', 'ie', 'it', 'lv', 'lt', 'lu', 'mt', 'nl', 'pl', 'pt', 'ro', 'sk', 'si', 'es', 'se']
      fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}&accept-language=en`)
        .then(response => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error('Something went wrong.')
          }
        })
        .then((data) => {
          if (data.error === 'Unable to geocode') {
            setGeoAlert(true)
            setTimeout(() => {
              setGeoAlert(false)
            }, 5000)
          } else if (allowedCountry.includes(data.address.country_code)) {
            setAddPlace(true)
            setAddress(data.address)
            // console.log(data)
          } else {
            setRestrictAlert(true)
            setTimeout(() => {
              setRestrictAlert(false)
            }, 5000)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      return null
    }
  }

  function getPlace() {
    // console.log('query place data')
    placesRef.doc(placeDocId).get().then((doc) => {
      if (doc.exists) {
        setPlace({
          id: doc.id,
          address: doc.data().address,
          contributor: doc.data().contributor,
          properties: doc.data().properties,
          rating: doc.data().rating,
          lat: doc.data().geometry.coordinates.latitude,
          lng: doc.data().geometry.coordinates.longitude,
          added: doc.data().properties.added.toDate().toLocaleDateString(),
          lastModified: doc.data().properties.lastModified.toDate().toLocaleDateString()})
      } else {
        // doc.data() will be undefined in this case
        // console.log("No such document!")
      }
    })
  }

  function getUserRating() {
  // console.log('query rating data')
    if (isLoggedIn) {
      ratingsRef.doc(profile.uid + '_' + placeId).get().then((doc) => {
        if (doc.exists) {
          setUserRating({
            added: doc.data().added.toDate().toLocaleDateString(),
            pid: doc.data().pid,
            uid: doc.data().uid,
            value: doc.data().val
          })
        } else {
          // doc.data() will be undefined in this case
          // console.log("No such document!")
          setUserRating()
        }
      })
    } else {
      setUserRating()
    }
  }

  useEffect(() => {

    setLoading(true)

    placesRef.onSnapshot((querySnapshot) => {
      const items = []
      querySnapshot.forEach((doc) => {
        if (doc.exists) {
          items.push({
            id: doc.id,
            pid: doc.data().properties.pid,
            lat: doc.data().geometry.coordinates.latitude,
            lng: doc.data().geometry.coordinates.longitude,
            type: doc.data().properties.type
          })
        } else {
          // doc.data() will be undefined in this case
          // console.log("No such document!")
        }
      })

      // console.log('loading ')
      setPlaces(items)
      setLoading(false)

    })

  }, [])

  useEffect(() => {
    if (placeId !== undefined) {
      getPlace()
      getUserRating()
    }
  }, [placeId])

  useEffect(() => {
    if (refreshSidebar === true) {
      getPlace()
      getUserRating()
      setRefreshSidebar(false)
    }
  }, [refreshSidebar])

  // map loading
  if (loading) {
    return (
      <>
        <div style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </>
    )
  }

  return (
    <Layout>
      <GatsbySeo
        titleTemplate='Map | %s'
      />
      <div className="mapbox">
        <div className="row-fluid">
          <Map
            center={[ params.lat || 48.974244, params.lng || 14.478729]}
            zoom={params.z || 5}
            minZoom={3}
            maxZoom={19}
            maxBounds={bounds}
            ref={map}
            attributionControl={false}
            onMoveEnd={() => centerCoord()}
            zoomControl={false}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <AttributionControl 
              position="bottomright" 
              prefix="<a href='https://leafletjs.com'>Leaflet</a> | © <a href='https://pinavo.cz/'>Pinavo Maps</a> contributors" 
            />
            <MapSidebar
              profile={profile}
              place={place}
              userRating={userRating}
              isLoggedIn={isLoggedIn}
              sidebarState={sidebarState}
              setSidebarState={setSidebarState}
              setReport={setReport}
              setRatePlace={setRatePlace}
              setLoginRequired={setLoginRequired}
            />
            <EditingPanel
              editPanelState={editPanelState}
              setEditPanelState={setEditPanelState}
              confirmPlacement={confirmPlacement}
              lat={lat}
              lng={lng}
            />
            <Control style={{border: 'none'}} position="topright">
              <OverlayTrigger
                placement='left'
                overlay={
                  <Tooltip placement='left'>
                    Add new place
                  </Tooltip>
                }
              >
                <Button 
                  className="leaflet-custom-control-button"
                  role="button"
                  aria-label="Add new place"
                  tabIndex={0}
                  onClick={() => enableEditMode()}
                >
                  <TbCirclePlus size={24} />
                </Button>
              </OverlayTrigger>
            </Control>
            <LocateControl startDirectly />
            <ZoomControl 
              position='bottomright'
            />
            <Search
              className="search"
              position="topleft"
              provider="OpenStreetMap"
              inputPlaceholder="Search place"
              markerIcon={searchIcon}
              showPopup={false}
            />
            <Control style={{border: 'none'}} position="topleft">
              <OverlayTrigger
                placement='right'
                overlay={
                  <Tooltip placement='right'>
                    Show map legend
                  </Tooltip>
                }
              >
                <Button
                  className="leaflet-custom-control-button"
                  role="button"
                  aria-label="Show map legend"
                  tabIndex={0}
                  onClick={handleShowLegend}
                >
                  <TbBook2 size={24} />
                </Button>
              </OverlayTrigger>
            </Control>
            <ScaleControl position="bottomleft" />
            <MarkerClusterGroup>
              {
                places.map((place) => {
                  const icon = L.icon({
                    iconUrl: 'https://pinavo.cz/assets/pin-icon/' + place.type + '.svg',
                    iconAnchor: [16, 50],
                    popupAnchor: [0, -47],
                    iconSize: [32, 50],
                  })
                  return (
                    <Marker
                      key={`place-${place.pid}`}
                      position={[place.lat, place.lng]}
                      icon={icon}
                      onClick={() => { markerClick(place) }}
                    >
                    </Marker>
                  )
                })
              }
            </MarkerClusterGroup>
          </Map>
        </div>
        <div className="row-fluid overlay-alert">
          <Alert variant="info" show={editSuccess} className="mt-2">
            <font style={{fontSize: 'small'}}>New place successfully added.</font>
          </Alert>
          <Alert variant="danger" show={editFailed.show} className="mt-2">
            <font style={{fontSize: 'small'}}>{editFailed.error}</font>
          </Alert>
          <Alert variant="danger" show={geoAlert} className="mt-2">
            <font style={{fontSize: 'small'}}>There is not enough map data available here.</font>
          </Alert>
          <Alert variant="danger" show={restrictAlert} className="mt-2">
            <font style={{fontSize: 'small'}}>Sorry, you can't add new places here yet.</font>
          </Alert>
        </div>
        <div className="row-fluid overlay-modal">
          <RatePlaceModal
            profile={profile}
            placeId={placeId}
            placeDocId={placeDocId}
            userRating={userRating}
            ratePlace={ratePlace}
            setRatePlace={setRatePlace}
            handleCloseRatePlace={handleCloseRatePlace}
            handleRefreshSidebar={handleRefreshSidebar}
          />
          <ReportPlaceModal
            profile={profile}
            report={report}
            setReport={setReport}
            handleCloseReport={handleCloseReport}
            placeId={placeId}
          />
          <AddPlaceModal
            profile={profile}
            lat={lat}
            lng={lng}
            addPlace={addPlace}
            setAddPlace={setAddPlace}
            handleCloseAddPlace={handleCloseAddPlace}
            setEditSuccess={setEditSuccess}
            setEditFailed={setEditFailed}
            address={address}
          />
          <MapLegend
            legend={legend}
            handleCloseLegend={handleCloseLegend}
          />
          <LoginRequiredModal
            loginRequired={loginRequired}
            handleCloseLoginRequired={handleCloseLoginRequired}
          />
        </div>
      </div>
    </Layout>
  )
}
