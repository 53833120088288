import React, { Component } from 'react'
import { Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faFileImage } from '@fortawesome/free-solid-svg-icons'
import withStyles from '@material-ui/core/styles/withStyles'
import CustomFileInputStyle from './CustomFileInputStyle'
import classnames from 'classnames'

class CustomImageInput extends Component {
  constructor(props) {
    super(props)
    this.fileUpload = React.createRef()
    this.showFileUpload = this.showFileUpload.bind(this)
    this.handleImageChange = this.handleImageChange.bind(this)
  }

  state = {
    file: undefined,
    imagePreviewUrl: undefined
  }

  showFileUpload() {
    if (this.fileUpload) {
      this.fileUpload.current.click()
    }
  }

  handleImageChange(e) {
    e.preventDefault()
    let reader = new FileReader()
    let file = e.target.files[0]
    if (file) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result
        })
      }
      reader.readAsDataURL(file)
      this.props.setFieldValue(this.props.field.name, file)
    }
  }

  showPreloadImage() {
    const { errorMessage, classes } = this.props
    const { file, imagePreviewUrl } = this.state

    let comp = null

    if (errorMessage) {
      comp = <span style={{alignItems: 'center'}}><FontAwesomeIcon icon={faExclamationCircle} size="3x" color="white" /><p style={{ fontSize: '80%', color: 'white', marginBottom: '0px'}}>{errorMessage}</p></span>
    } else if (file) {
      comp = (
        <Image className={classes.fileThumb} src={imagePreviewUrl} alt="..." />
      )
    } else {
      comp = <span style={{alignItems: 'center'}}><FontAwesomeIcon icon={faFileImage} size="3x" color="#3771c8" /><p style={{ fontSize: '80%', color: 'white', marginBottom: '0px'}}>Click to upload</p></span>
    }
    return comp
  }

  componentDidMount() {
    // console.log(this.fileUpload.current)
  }

  render() {
    const { errorMessage, classes } = this.props
    const { name } = this.props.field

    const InputStyle = classnames(
      classes.bigFile,
      this.state.file ? [classes.whiteBack] : [classes.primaryBack],
      { [classes.errorBack]: errorMessage }
    )

    return (
      <div className={classes.container}>
        <input
          className={classes.hidden}
          id={name}
          name={name}
          type="file"
          onChange={this.handleImageChange}
          ref={this.fileUpload}
        />
        <div onClick={this.showFileUpload} className={InputStyle}>
          {this.showPreloadImage()}
        </div>
      </div>
    )
  }
}

export default withStyles(CustomFileInputStyle)(CustomImageInput)
