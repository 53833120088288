import React from 'react'
import { firestore, firebase } from 'gatsby-theme-firebase'
import { Modal, Button, Container, Row } from 'react-bootstrap'
import Rating from 'react-rating'

export default function RatePlaceModal(props) {

  function ratePlace(ratingValue) {

    const ratingRef = firestore.collection('pin_ratings').doc(props.profile.uid + '_' + props.placeId)
    const placeRef = firestore.collection('pin_places').doc(props.placeDocId)

    let newNumRatings
    let redNumRatings
    let curRatingTotal
    let redRatingTotal
    let oldRatingTotal
    let newAvgRating
    let redAvgRating

    const data = {
      uid: props.profile.uid,
      pid: props.placeId,
      val: Number(ratingValue),
      added: firebase.firestore.FieldValue.serverTimestamp(),
    }

    // add the new rating and update the aggregate totals thru transaction
    return firestore.runTransaction((transaction) => {
      return transaction.get(placeRef).then((res) => {
        if (!res.exists) {
            throw "Document does not exist!"
        }
        // check if there is already rating from given user
        // console.log('check if there is already rating from given user')
        if (props.userRating !== undefined) {
          // remove user rating completely if clicked rating value is same original user rating value
          if (props.userRating.value === ratingValue) {
            // console.log('remove user rating completely if clicked rating value is same original user rating value')
            // reduce current number of ratings for recalculation
            // console.log('reduce current number of ratings for recalculation')
            redNumRatings = res.data().rating.numRatings - 1
            // prevent computing with zero value / infinite result
            if (redNumRatings === 0) {
              // console.log('prevent computing with zero value / infinite result')
              // commit to Firestore
              // console.log('commit to Firestore')
              transaction.update(placeRef, {
                "rating.numRatings": 0,
                "rating.avgRating": 0
              })
              // delete doc with user rating
              // console.log('delete doc with user rating')
              transaction.delete(ratingRef)
              // refresh sidebar
              props.handleRefreshSidebar(true)
            } else {
              // subtract the original rating from the average
              // console.log('subtract the original rating from the average')
              curRatingTotal = res.data().rating.avgRating * res.data().rating.numRatings
              redAvgRating = (curRatingTotal - props.userRating.value) / redNumRatings
              // compute new number of ratings
              // console.log('compute new number of ratings')
              newNumRatings = redNumRatings
              // compute new average rating
              // console.log('compute new average rating')
              redRatingTotal = redAvgRating * redNumRatings
              newAvgRating = redRatingTotal / newNumRatings
              // commit to Firestore
              // console.log('commit to Firestore')
              transaction.update(placeRef, {
                "rating.numRatings": newNumRatings,
                "rating.avgRating": newAvgRating
              })
              // delete doc with user rating
              // console.log('delete doc with user rating')
              transaction.delete(ratingRef)
              // refresh sidebar
              props.handleRefreshSidebar(true)
            }
          } else {
            // reduce current number of ratings for recalculation
            // console.log('reduce current number of ratings for recalculation')
            redNumRatings = res.data().rating.numRatings - 1
            // subtract the original rating from the average
            // console.log('subtract the original rating from the average')
            curRatingTotal = res.data().rating.avgRating * res.data().rating.numRatings
            redAvgRating = (curRatingTotal - props.userRating.value) / (redNumRatings === 0 ? 1 : redNumRatings)
            // compute new number of ratings
            // console.log('compute new number of ratings')
            newNumRatings = redNumRatings + 1
            // compute new average rating
            // console.log('compute new average rating')
            redRatingTotal = redAvgRating * redNumRatings
            newAvgRating = (redRatingTotal + ratingValue) / newNumRatings
            // commit to Firestore
            // console.log('commit to Firestore')
            transaction.update(placeRef, {
              "rating.numRatings": newNumRatings,
              "rating.avgRating": newAvgRating
            })
            transaction.set(ratingRef, data)
            // refresh sidebar
            props.handleRefreshSidebar(true)
          }
        } else {
          // compute new number of ratings
          // console.log('compute new number of ratings')
          newNumRatings = res.data().rating.numRatings + 1
          // compute new average rating
          // console.log('compute new average rating')
          oldRatingTotal = res.data().rating.avgRating * res.data().rating.numRatings
          newAvgRating = (oldRatingTotal + ratingValue) / newNumRatings
          // commit to Firestore
          // console.log('commit to Firestore')
          transaction.update(placeRef, {
            "rating.numRatings": newNumRatings,
            "rating.avgRating": newAvgRating
          })
          transaction.set(ratingRef, data)
          // refresh sidebar
          props.handleRefreshSidebar(true)
        }
      })
    })
  }

  return (
    <Modal dialogClassName="rate-place-modal" show={props.ratePlace} onHide={props.handleCloseRatePlace} centered>
      <Modal.Header closeButton>
        <Modal.Title>Rate the place</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="justify-content-center pt-3 pb-4" style={{ textAlign: 'center' }}>
            <Rating
              emptySymbol={<img src="../assets/pin-icon/pin-rating-empty.svg" style={{ width: '35px' }}/>}
              fullSymbol={<img src="../assets/pin-icon/pin-rating-full.svg" style={{ width: '35px' }}/>}
              initialRating={props.userRating !== undefined ? props.userRating.value : 0}
              onClick={(ratingValue) => ratePlace(ratingValue)}
            />
          </Row>
          <Row>
            <p>Click / tap on a drop to rate the place (1-5, from worst to best). Tap again on selected drop to remove rating.</p>
            <p>We recommend taking into account the quality of the water, the technical condition of the drinking fountain itself and the cleanliness of the surroundings.</p>
            <p>If you are unsure, just rate it as if you were going to share your experience with family or friends.</p>
          </Row>
        </Container>
        <Button style={{ float: 'right' }} onClick={props.handleCloseRatePlace}>Close</Button>
      </Modal.Body>
    </Modal>
  )
}
