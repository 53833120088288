import { useEffect } from 'react'
import { useLeaflet } from 'react-leaflet'
import Locate from 'leaflet.locatecontrol'
import { TbCurrentLocation } from 'react-icons/tb'

export default function LocateControl() {

  console.log(<TbCurrentLocation />)

  const { map } = useLeaflet()

  useEffect(() => {

    // geo locate props
    const locateOptions = {
      icon: 'ti ti-current-location',
      position: 'topright',
      maxZoom: 18,
      onActivate: () => {} // callback before engine starts retrieving locations
    }

    const lc = new Locate(locateOptions)
    // console.log(lc);
    lc.addTo(map)

  }, [map])

  return null

}
