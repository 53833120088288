import React, { createRef } from 'react'
import { firestore, firebase } from 'gatsby-theme-firebase'
import { Row, Button, Modal, Form, Col } from 'react-bootstrap'
import { Formik, Field } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'
import CustomFileInput from '../map/customFileInput'
import ReCAPTCHA from "react-google-recaptcha"

export default function AddPlaceModal(props) {

  // reCAPTCHA
  // const SITE_KEY = "6Lc9HJchAAAAAPscKC3-s98QtdSxMdgTLFjjeXFY"
  // const reCaptchaRef = createRef()

  // file upload parameters
  const FILE_SIZE = 3072 * 3072 // 204* * 2048 = cca 4,2 MB .. 3072 * 3072 = cca 9 MB
  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/png'
  ]

  // form validation
  const schema = yup.object().shape({
    name: yup.string()
      .required()
      .min(4, 'Too Short!')
      .max(25, 'Too Long!')
      .matches(/^[A-Za-z0-9À-ž#.' -]+$/, 'Some symbols are not allowed'),
    description: yup.string()
      .min(15, 'Too Short!')
      .max(200, 'Too Long!')
      .matches(/^[A-Za-z0-9À-ž#.,/()' -]+$/, 'Some symbols are not allowed'),
    file: yup
      .mixed()
      .required('A file is required')
      .test(
        'fileFormat',
        'Unsupported Format',
        value => value && SUPPORTED_FORMATS.includes(value.type)
      )
      .test(
        'fileSize',
        'File too large',
        value => value && value.size <= FILE_SIZE
      )
    // recaptcha: yup.string().required()
  })

  // submit place to database
  function submitPlace(values, e) {

    // generate unique ID for place
    const pidNew = uuidv4()

    function getFileExtension(fileName) {
      return fileName.split('.').pop()
    }

    // const related to Storage
    const storage = firebase.storage()
    const fileExtension = getFileExtension(values.file.name)
    const imagesRef = storage.ref('pinavo/map/pin').child(pidNew).child(`${pidNew}.${fileExtension}`).put(values.file)

    // const related to Firestore
    const placesRef = firestore.collection('pin_places')

    // place data payload
    const data = {
      geometry: {
        coordinates: new firebase.firestore.GeoPoint(props.lat, props.lng)
      },
      properties: {
        pid: pidNew,
        added: firebase.firestore.FieldValue.serverTimestamp(),
        lastModified: firebase.firestore.FieldValue.serverTimestamp(),
        verified: false,
        title: values.name,
        description: values.description,
        type: values.type,
        activationType: values.activation,
        nonstop: values.nonstop,
        active: values.active,
        picture: `${pidNew}.${fileExtension}`
      },
      address: props.address,
      contributor: {
        uid: props.profile.uid,
        name: props.profile.displayName
      },
      rating: {
        avgRating: 0,
        numRatings: 0
      },
      shallop: {
        sid: '', // wip
        name: '' // wip
      }
    }

    // save data to database
    placesRef.doc().set(data)
      .then((docRef) => {

        imagesRef.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            // console.log('Upload is ' + progress + '% done')
            switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              // console.log('Upload is paused')
              break
            case firebase.storage.TaskState.RUNNING: // or 'running'
              // console.log('Upload is running')
              break
            // no default
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
            case 'storage/unauthorized':
            // User doesn't have permission to access the object
              break
            case 'storage/canceled':
              // User canceled the upload
              break
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break
            // no default
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            imagesRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
              // console.log('File available at', downloadURL)
            })
          }
        )

        // show alert to user
        props.setEditSuccess(true)
        setTimeout(() => {
          props.setEditSuccess(false)
        }, 5000)
      })
      .catch((error) => {
        console.error('Error adding document: ', error)

        // show alert to user
        props.setEditFailed({show: true, error: 'Error adding new place.'})
        setTimeout(() => {
          props.setEditFailed({show: false, error: ''})
        }, 5000)
      })

    // hide addPlace modal
    props.setAddPlace(false)
  }

  return (
    <Modal show={props.addPlace} onHide={props.handleCloseAddPlace} style={{ paddingLeft: '0px' }} centered>
      <Modal.Header closeButton>
        <Modal.Title>New place</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={schema}
          onSubmit={submitPlace}
          initialValues={{
            name:'Drinking fountain',
            description:'No description.',
            type: '1',
            active: '1',
            nonstop: '1',
            activation: '1',
            file: undefined
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue,
            setFieldTouched
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <Form.Group as={Col} md="4" controlId="validationFormikImage" className="mb-3">
                  <Form.Label>Photo</Form.Label>
                  <Field
                    name="file"
                    component={CustomFileInput}
                    title="Select a file"
                    setFieldValue={setFieldValue}
                    errorMessage={errors['file'] ? errors['file'] : undefined}
                    touched={touched['file']}
                    style={{ display: 'flex' }}
                    onBlur={handleBlur}
                  />
                </Form.Group>
                <Form.Group as={Col} md="8" controlId="validationFormikType">
                  <Form.Label>Name <i>(can stay as is)</i></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Another amazing drinking fountain"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mt-3">
                <Form.Group as={Col} controlId="validationFormikTextarea">
                  <Form.Label>Description <i>(optional)</i></Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Something interesting or useful"
                    rows="3"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    isInvalid={!!errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mt-3">
                <Form.Group as={Col}>
                  <Form.Label>Type</Form.Label>
                  <Form.Select
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                  >
                    <option value="1">Standard</option>
                    <option value="2">Mineral</option>
                    <option value="3">Natural</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>
                    Activation
                  </Form.Label>
                  <Form.Select
                    id="activation-type"
                    name="activation"
                    value={values.activation}
                    onChange={handleChange}
                  >
                    <option value="1">Touch</option>
                    <option value="2">Sensor</option>
                    <option value="3">Stepping</option>
                    <option value="4">Spring</option>
                    <option value="5">Free-flow</option>
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row className="mt-3">
                <Form.Group as={Col}>
                  <Form.Label>
                    Active
                  </Form.Label>
                  <Form.Select
                    id="active"
                    name="active"
                    value={values.active}
                    onChange={handleChange}
                  >
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                    <option value="2">Unknown</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>
                    Nonstop
                  </Form.Label>
                  <Form.Select
                    id="available-nonstop"
                    name="nonstop"
                    value={values.nonstop}
                    onChange={handleChange}
                  >
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row className="mt-3 mb-4">
                <Form.Group as={Col}>
                  <Form.Label>Latitude</Form.Label>
                  <Form.Control
                    type="text"
                    value={props.lat}
                    readOnly
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Longitude</Form.Label>
                  <Form.Control
                    type="text"
                    value={props.lng}
                    readOnly
                  />
                </Form.Group>
              </Row>
              <Button style={{ float: 'right' }} type="submit">Submit</Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}
