import React from 'react'
import { Modal, Col, Image, Row, Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faBook} from '@fortawesome/free-solid-svg-icons'
import packageJson from '../../../package.json'

export default function MapLegend(props) {

  const ver = packageJson.version

  return (
    <Modal show={props.legend} onHide={props.handleCloseLegend} centered>
      <Modal.Header closeButton>
        <Modal.Title>Pinavo Maps v{ver}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="legend-modal">
          <Row className="justify-content-center text-center pt-3" style={{backgroundColor: '#1f385a'}}>
            <Col xs={4} sm={4} md={4}>
              <Image
                width={50}
                height={'auto'}
                alt="standard"
                src={'../assets/pin-icon/1.svg'}
              />
              <p>Standard drinking <br/>fountain</p>
            </Col>
            <Col xs={4} sm={4} md={4}>
              <Image
                width={50}
                height={'auto'}
                alt="mineral"
                src={'../assets/pin-icon/2.svg'}
              />
              <p>Mineral drinking <br/>fountain</p>
            </Col>
            <Col xs={4} sm={4} md={4}>
              <Image
                width={50}
                height={'auto'}
                alt="natural"
                src={'../assets/pin-icon/3.svg'}
              />
              <p>Natural spring</p>
            </Col>
          </Row>
          <Row className="pt-5">
            <Col xs={12} md={12}>
              <h5>Contributor?</h5>
              <p>You can add a new place to the map in <i>edit mode</i> - turn it on by clicking on &nbsp;<FontAwesomeIcon icon={faPlus}/>&nbsp; button on the right side.</p>
              <p>See &nbsp;<FontAwesomeIcon icon={faBook}/> <a href="/map/docs" style={{ color: '#9cc005', fontWeight: 'bold' }}>docs</a> for more information.</p>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  )
}
